@import "../index.scss";

.persevere {
    margin-bottom: 4rem;
    .slide-container-col { 
        .overlay {
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            h2 {
                margin-top: 4rem;
                transform: translateY(5rem);
                opacity: 0;
                &.active {
                    transform: translateY(0);
                    opacity: 1;
                    transition: transform 500ms ease-out, opacity 300ms linear;
                    transition-delay: 100ms;
                }
            }
        }
        .persevere-text-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 4rem 1rem;
        }
        .persevere-text {
            margin: 0 2rem;
            opacity: 0;
            color: #fff;
            &.active {
                opacity: 1;
                transition: opacity 500ms linear;
            }
            .persevere-text-row {
                display: flex;
                align-items: center;
                justify-content: center;
                &.large {
                    @include font-size(15px, 34px);
                }
                &.small {
                    @include font-size(10px, 15px);
                }
            }
        }
    }
}

@for $i from 1 to 4 {
    .persevere .persevere-text-container .persevere-text.active:nth-child(#{$i}) { transition-delay: ($i+1) * 300ms; }
}

//sm
@media only screen and (min-width: 48em) {
    .persevere .persevere-text-container {
        width: calc( 100% - 4rem );
        padding: 4rem 2rem;
    }
}
//md
@media only screen and (min-width: 62em) {
    .persevere .persevere-text-container {
        width: calc( 100% - 10rem );
        padding: 4rem 5rem;
    }
}
//lg:
@media only screen and (min-width: 75em) {
    .persevere .persevere-text-container {
        width: calc( 100% - 20rem );
        padding: 4rem 10rem;
    }
}