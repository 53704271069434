@import "../index.scss";

.about-page {
    p, h3 {
        text-align: left;
    }
    .about-car {
        p {
            margin-top: 0;
        }
    }

    .tell-me-more {
        cursor: pointer;
        color: #000;
        background-color: transparent;
        border: 1px solid #000;
        padding: 1rem 2rem;
        border-radius: 2rem;
        margin-top: 0 !important;
        margin-bottom: 4rem;
        text-decoration: none;

        &:hover, &:focus &:active {
            outline: none !important;
            color: #DEFA04;
            border: 1px solid #DEFA04;
        }
    }
}

.about-detail {
    margin-bottom: 4rem;
    width: 100%;

    .image-wrapper {
        &.first {
            @include fluid-aspect(0.7 1);
        }
        &:not(.first) {
            @include fluid-aspect(1 1);
        }
        @include fluid-aspect(16 9);
        overflow: hidden;
        
        img {
            object-fit: cover;
            transition: transform 300ms;
        }

        &.active img {
            transform: scale(1.15);
        }
    }
}

.about-detail .fluid-image-container {
    max-height: 50vh;
    .fluid-image {
        width: 100vw;
        height: 100%;
        max-width: 100vw;
    }
}

@media only screen and (min-width: 48em) {
    .about-detail {
        flex: 1;
        max-width: 100%;
    }
    .about-detail .fluid-image-container {
        max-height: 433px;
        position: relative;
        .fluid-image {
            width: auto;
            height: 125%;
        }
    }
    .about-detail.first {
        padding-right: 2rem;
    }
    .about-detail.first {
        width: 433px;
    }
    .about-detail:not(.first) {
        width: 488px;
    }
}

.about-detail p {
    text-align: left;
}