.page {
    position: relative;
    margin-left: -2rem;
    margin-right: -2rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
    flex: 1;
}

.page .page-alternate {
    position: absolute;
    left: 0;
    right: 0;
}
.coming-soon-page {
    .col {
        p {
            text-align: center;
            margin: 2rem 0;
        }
    }
}

//sm
@media only screen and (min-width: 48em) {
    .page {
        .col {
            p {
                margin: 2.5rem 0;
            }
        }
    }
}
//md
@media only screen and (min-width: 62em) {
    .page {
        .col {
            p {
                margin: 3rem 0;
            }
        }
    }
}
//lg:
@media only screen and (min-width: 75em) {
    .coming-soon-page {
        .col {
            p {
                margin: 4rem 0;
            }
        }
    }
}
