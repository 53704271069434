// ==============================
// Font size mixin
// ==============================

//  default font scale bounding
$font-lock-min:  400px !default;
$font-lock-max: 1000px !default;

//  strip units helper function
@function num($input){  @return $input/($input * 0 + 1); }

//  font-size
@mixin font-size(
  $min,                         // font-size
  $max: null,                   // optional maximum font size
  $lock-min: $font-lock-min,  // optional minimum viewport width
  $lock-max: $font-lock-max   // optional maximum viewport width
){
  & { font-size: $min; }
  @if $max {
    @media (min-width: $lock-min) and (max-width: $lock-max){
      & {
        font-size: calc(
                #{$min} +
                #{num($max) - num($min)} *
                (100vw - #{$lock-min}) /
                #{num($lock-max) - num($lock-min)}
        );
      }
    }
    @media (min-width: $lock-max){
      & { font-size: $max; }
    }
  }
}

@font-face {
  font-family: 'Univers LT Roman';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/UniversLT55Roman.woff') format('woff');
}
@font-face {
  font-family: 'rightgrotesk';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/rightgrotesk.woff') format('woff');
}

@mixin fluid-aspect($ratio: 1 1, $selector: "> :first-child") {
	$selector: unquote($selector);

	padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
	position: relative;

	#{$selector} {
		left: 0;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

body {
  margin: 0;
  letter-spacing: 0.01em;
  line-height: 1.6;
  font-family: 'Univers LT Roman', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include font-size(12px, 15px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}