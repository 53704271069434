@import "../index.scss";

$maxHeight: calc( 100vh - 10rem );
$maxPixel: 1000px;

.slide-container-col {
    height: min( #{ $maxHeight }, #{ $maxPixel } );
    .image-slide-container {
        position: absolute;
        left: 50%;
        width: calc(100vw - 1rem);
        height: min( #{ $maxHeight }, #{ $maxPixel } );
        max-width: 2000px;
        transform: translateX(-50%);
        overflow: hidden;
        background-color: #fff;

        .image {
            opacity: 0;
            position: absolute;
            object-fit: cover;
            transition: transform 26s linear, opacity 3s linear;
            min-height: min( #{ $maxHeight }, #{ $maxPixel } );
            max-width: 200%;
            &.active {
                opacity: 1;
            }
        }

        .overlay {
            position: absolute;
            padding: 2rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #DEFA04;
            text-transform: uppercase;
            cursor: default;
            user-select: none;
            h2 {
                @include font-size(15px, 80px);
                font-family: 'rightgrotesk';
                text-align: center;
                font-weight: normal;
                letter-spacing: .25rem;
                line-height: 0.9;
                margin: 1.5rem 0;
            }
            p {
                @include font-size(10px, 18px);
                color: #DEFA04;
                margin: 1rem 0;
            }
        }
        .pager {
            position: absolute;
            right: 1rem;
            bottom: 4rem;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            cursor: pointer;
    
            .pager-item {
                width: .5rem;
                height: .5rem;
                border-radius: .5rem;
                border: 1px solid #000;
                margin-bottom: .2rem;
    
                &.active {
                    background-color: #000;
                }
            }
        }
        .arrow-down {
            transform: translateY(5rem);
            opacity: 0;
            svg {
                transform:scale(1.25, 1.25) rotate(-90deg);
            }
            &.active {
                opacity: 1;
                transform: translateY(0);
                transition: transform 500ms ease-out, opacity 300ms linear;
                transition-delay: 600ms;
            }
            &:hover {
                svg {
                    transform: scale(1.25, 1.25) rotate(-90deg);
                }
            }
            &:active {
                svg {
                    transform: scale(0.95, 0.95) rotate(-90deg);
                }
            }
        }
    }
}

//sm
@media only screen and (min-width: 48em) {
    .slide-container-col .image-slide-container {
        width: calc(100vw - 4rem);
    }
}
//md
@media only screen and (min-width: 62em) {
    .slide-container-col .image-slide-container {
        width: calc(100vw - 4rem);
    }
}
//lg:
@media only screen and (min-width: 75em) {
    .slide-container-col .image-slide-container {
        width: calc(100vw - 4rem);
    }
}
