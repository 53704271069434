.admin-page {
    .row {
        max-width: 100%;
    }
    .admin-panel {
        position: relative;
        background-color: #fff;
        padding: 1rem 2rem;
    }
    .credential {
        display: flex;
        align-items: center;
        max-width: calc( 100% - 4rem );
        margin-bottom: 1rem;
        >:first-child {
            min-width: 100px;
        }
        >:nth-child(2) {
            min-width: 200px;
            margin-right: 2.5rem;
        }
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 2rem 0;
    }
    .form-post {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: order 1s;
        padding: .5rem 0;
        .post {
            padding: .1rem 0;
            margin: .1rem 0;
            width: 100%;
            display: flex;
            align-items: center;
            *:not(:last-child) {
                margin-right: 1rem;
            }
            textarea {
                flex: 1;
            }
        }
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 0;
        margin: 0;
        border: none;
        width: 1.5rem;
        height: 1.5rem;
        &:disabled {
            fill: rgba($color: #000000, $alpha: 0.5);
        }
        &:hover:not(:disabled) {
            fill: #B3F425;
        }
        &:active:not(:disabled) {
            transform: scale(0.98, 0.98);
        }
        &.arrow {
            width: 1rem;
            height: 1rem;
        }
    }
}