.image-box {
    max-width: 100%;
    .image-wrapper {
        position: relative;
        max-width: 100%;
        overflow: hidden;
        .images {
            display: flex;
            position: absolute;
            height: 100%;
            transition: transform 1s ease-in-out;
            .image-slot {
                flex: 1;
                overflow: hidden;
                img {
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .arrow-left, .arrow-right {
            font-family: 'rightgrotesk';
            position: absolute;
            top: 50%;
            width: 1rem;
            height: 1rem;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
            color: #fff;
            cursor: pointer;
        }

        .arrow-left {
            left: 1rem;
            svg {
                transform: scale(0.5, 0.5);
                margin-left: -2px;
            }
        }

        .arrow-right {
            right: 1rem;
            svg {
                transform: scale(0.5, 0.5) rotate(180deg);
            }
        }
    }

    .pager {
        display: flex;
        padding: 1rem;
        cursor: pointer;

        .pager-item {
            width: .4rem;
            height: .4rem;
            border-radius: .4rem;
            border: 1px solid #000;
            margin-right: .2rem;

            &.active {
                background-color: #000;
            }
        }
    }
    //sm
    @media only screen and (min-width: 48em) {
        .image-wrapper {
            .arrow-left, .arrow-right  {
                width: 3rem;
                height: 3rem;
            }
            .arrow-left {
                left: 2rem;
                svg {
                    transform: scale(1, 1);
                    margin-left: -5px;
                }
            }
            .arrow-right {
                right: 2rem;
                svg {
                    transform: scale(1, 1) rotate(180deg);
                }
            }
        }
    }
}