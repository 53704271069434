@import "../index.scss";

.col.masonry-col {
    padding-right: 0;
    padding-left: 0;
}

//sm
@media only screen and (min-width: 48em) {
    .col.masonry-col {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.fb-container {
    width: 500px;
    max-width: 100%;
    margin: 1rem 0;
    overflow: hidden;
    iframe {
        border: none;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        transition: box-shadow 1s;
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
        }
    }
}
