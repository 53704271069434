@import "../index.scss";

.footer {
    padding: 3rem 0;
    @include font-size(10px, 14px);
}
.footer .row .col:not(:last-child) {
    margin-right: 2rem;
}
.footer .footer-logo {
    margin: .5rem 0;
}

.footer .footer-text {
    flex-wrap: wrap;
    > span {
        margin: .5rem .75rem .5rem 0;
    }
}

.footer .social-links {
    user-select: none;
    margin-bottom: 3rem;
    @include font-size(10px, 11px);

    .row {
        transition: transform 200ms;
        a {
            display: flex;
            align-items: flex-end;
            text-decoration: none;
            color: #000;
            cursor: pointer;
            &[href="/"] {
                opacity: 0.5;
                pointer-events: none;
                cursor: arrow;
            }
        }
        svg {
            height: 25px;
            margin-right: 0.75rem;
            fill: #000;
        }
        span {
            margin: 0;
            border-bottom: 1px solid transparent;
        }

        &:focus, &:hover, &:active {
            outline: none !important;
            a:not([href="/"]) {
                span {
                    border-bottom: 1px solid #000;
                }
            }
        }
        &:active span {
            transform: scale(0.98, 0.98);
        }
    }
}
.footer .webdesign {
    @include font-size(9px, 12px);
    margin: .5rem 0;
    align-items: flex-start;
    a {
        text-decoration: none;
        color: #000;
        border-bottom: 1px solid transparent;

        &:focus, &:hover, &:active {
            border-bottom: 1px solid #000;
            outline: none !important;
        }
        &:active {
            transform: scale(0.98, 0.98);
        }
    }
}
