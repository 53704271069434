@import "../index.scss";

.page.bumper-page {
    .image-slide-container .overlay {
        bottom: 4rem;

        h2, p {
            transform: translateY(5rem);
            opacity: 0;
            &.active {
                transform: translateY(0);
                opacity: 1;
                transition: transform 500ms ease-out, opacity 300ms linear;
                transition-delay: 100ms;
            }
        }
        p.active {
            transition-delay: 300ms;
        }
    }

    p {
        color: #92A2A2;
    }

    .receive-updates {
        text-decoration: none;
        cursor: pointer;
        color: #DEFA04;
        background-color: transparent;
        border: 1px solid #DEFA04;
        padding: 1rem 2rem;
        border-radius: 2rem;
        margin-top: 4rem !important;
        margin-bottom: 0 !important;

        &:hover, &:focus &:active {
            outline: none !important;
            color: #B3F425;
            border: 1px solid #B3F425;
        }
    }
}

.page.bumper-page p, .page.bumper-page button {
    text-align: center;
    margin: 4rem 0;
}
