.page-arcane {
    p, h3 {
        text-align: left;
    }
    .row .col p {
        margin-top: 0;
    }

    .slide-container-col { 
        .overlay {
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            h2 {
                transform: translateY(5rem);
                opacity: 0;
                &.active {
                    transform: translateY(0);
                    opacity: 1;
                    transition: transform 500ms ease-out, opacity 300ms linear;
                    transition-delay: 100ms;
                }
            }
        }
    }
}