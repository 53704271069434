@import "../index.scss";

.header {
    z-index: 900;
    min-height: 65px;
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .row {
        flex: 1;

        img {
            margin: 1rem .5rem;
        }

        .header-button {
            cursor: pointer;
            user-select: none;
            text-transform: uppercase;
            white-space: nowrap;
            margin: 1rem .5rem;
            @include font-size(10px, 14px);

            &:focus, &:hover, &:active {
                outline: none !important;
                &:not(:disabled) {
                    transform: scale(1.02, 1.02);
                }
            }
            &:active {
                transform: scale(0.98, 0.98);
            }
        }
    }
}

