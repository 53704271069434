.page .news-letter {
    margin: 1rem 0;
}
.page .news-letter .row > .col {
    margin: 0rem 0rem 1.5rem 0;
    &.contact-item { 
        min-width: 100%;
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;

        &:focus, &:hover, &:active {
            outline: none !important;
            color: #DEFA01;
            svg {
                fill: #DEFA01;
            }
        }
        &:active {
            transform: scale(0.98, 0.98);
        }
    }
    svg {
        height: 22px;
        margin-right: .75rem;
        fill: #000;
    }
    p {
        margin: 0;
    }
}
//sm
@media only screen and (min-width: 48em) {
    .page .news-letter .row > .col {
        //margin: 0rem 1rem 1rem 0;
        &.contact-item { 
            min-width: 0;
            margin-top: 2rem;
        }
    }
}

.page .news-letter input {
    width: 100%;
    min-width: 200px;
    text-align: center;
    background-color: transparent;
    padding: 0.5rem 1.5rem;
    border: 1px solid black;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
      }
    &:focus {
        outline: none !important;
        border:1px solid #DEFA01;
        &::placeholder {
            color: transparent;
        }
    }
}
.page .news-letter button {
    background-color: transparent;
    border: 1px solid black;
    border-radius: 2rem;
    padding: 0.75rem 1.25rem;
    cursor: pointer;

    &:focus, &:hover, &:active {
        outline: none !important;
        &:not(:disabled) {
            border:1px solid #DEFA01;
            color: #DEFA01;
        }
    }
    &:active {
        transform: scale(0.98, 0.98);
    }
    &:disabled {
        border-color: rgba(0,0,0,0.25);
    }
}